import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'
import 'core-js'

import Swiper from 'swiper/dist/js/swiper.js'
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  once: true
}
);


var Countdown = require('countdown-js')



var menuButton = document.getElementById('menu-btn')
var menuContainer = document.getElementById('menu-cont')

menuButton.addEventListener('click', function(event){
  if ( menuButton.classList.contains('close-btn') ){
    menuButton.classList.remove('close-btn')
    menuContainer.classList.remove('active')
  }else {
    menuButton.classList.add('close-btn')
  menuContainer.classList.add('active')
  }
})


var countdownContainer = document.getElementById('count-cont')

var eventDate = new Date(countdownContainer.dataset.date)



var now = new Date()
var ten_seconds = (eventDate - now)
var end = new Date(now.getTime() + ten_seconds)
var countdown = Countdown.timer(end, function(time) {
  document.getElementById('days').textContent = time.days
  document.getElementById('hours').textContent = time.hours
  document.getElementById('minutes').textContent = time.minutes
  document.getElementById('seconds').textContent = time.seconds
},function() {
  document.getElementById('seconds').textContent = '0'
  document.getElementById('countdown-end').setAttribute('style', 'display: block;')
})


var newsSwiper = new Swiper ('.news-slider', {
  loop: false,
  slidesPerView: 3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    // when window width is <= 320px
    1200: {
      slidesPerView: 2
    },
    // when window width is <= 480px
    767: {
      slidesPerView: 1
    }
  }
})

var swiper = new Swiper('.image-gallery', {
  init: true,
  observer: true,
  observeParents: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // other parameters
})
// init Swiper
//swiper.init();

var galleryWrapper = document.querySelector('.gallery-wrap')
var galleryItems = document.querySelectorAll('.gallery-item')
var activeSlide
var mainWrapper = document.querySelector('.main-wrapper')
Array.prototype.forEach.call( galleryItems, function(item){
  item.addEventListener( 'click', function(event){
    var slideIndex = item.getAttribute('data-target')
    if ( galleryWrapper.classList.contains('active') ){
      swiper.slideToLoop( slideIndex, 400, true )
    } else {
      galleryWrapper.classList.add('active')
      swiper.slideToLoop( slideIndex, 400, true )
    }
    item.classList.add('active-slide')
    mainWrapper.classList.add('active')
  } )
})

  swiper.on('slideChange', function () {
    activeSlide = swiper.activeIndex
    console.log(activeSlide)
    var activeItem = document.querySelector( '.gallery-item[data-target="'+ activeSlide +'"]' )
    Array.prototype.forEach.call( galleryItems, function(item){
      //var slideIndex = item.getAttribute('data-target')
        item.classList.remove('active-slide') 
    })
    activeItem.classList.add('active-slide')
  })

var closeGallery = document.getElementById('close-gallery')

if ( closeGallery ){

  closeGallery.addEventListener('click', function(event){
    galleryWrapper.classList.remove('active')
    mainWrapper.classList.remove('active')

    Array.prototype.forEach.call( galleryItems, function(item){
      //var slideIndex = item.getAttribute('data-target')
      item.classList.remove('active-slide') 
    })
  })
}

var anchors = document.querySelectorAll('a[href^="#"]')
Array.prototype.forEach.call(anchors, function(anchor){
  anchor.addEventListener('click', function(e){
   if(this.getAttribute('href') != '#') {
     e.preventDefault();
       if(document.querySelector(this.getAttribute('href'))) {
         document.querySelector(this.getAttribute('href')).scrollIntoView({ behavior: 'smooth', block: 'center' })
   
       }  else {
         return;
       }
   }
  })
})


var filterButtons = document.querySelectorAll('.filter-button')
var members = document.querySelectorAll('.member-box')
var target = 'alle'

if ( filterButtons ){
  Array.prototype.forEach.call( filterButtons, function(filter){
    if ( filter.classList.contains('active') ){
      target = filter.getAttribute('data-target')
    }
    if ( target !== 'alle' ){
      Array.prototype.forEach.call( members, function(member){
        if ( member.classList.contains( target ) ){
          member.classList.remove('hide')
        } else {
          member.classList.add('hide')
        }
        if ( target === 'all' ){
          member.classList.remove( 'hide' )
        }
    } )
    }
    filter.addEventListener( 'click', function(event){
      event.preventDefault()
      Array.prototype.forEach.call( filterButtons, function(filter){
        filter.classList.remove('active')
      })
      event.target.classList.add('active')
      target = filter.getAttribute('data-target')
      Array.prototype.forEach.call( members, function(member){
          if ( member.classList.contains( target ) ){
            member.classList.remove('hide')
          } else {
            member.classList.add('hide')
          }
          if ( target === 'all' ){
            member.classList.remove( 'hide' )
          }
      } )
    } )
  } )
}


jQuery(document).ready(function($){

  function GetURLParameter(sParam){
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam)
        {
            return sParameterName[1];
        }
    }
  }

  function famabps_filter() {
    var cat_value = $('.famabps_categoryfilter').val();
    var aw_value = $('.famabps_awardfilter').val();

    if(!aw_value) {
      var aw_value = 'all';
    }

    $('.filtercontainer').hide();

    if(cat_value != 'all' && aw_value != 'all') {
      $('.thb-portfolio .type-portfolio').show();
      $('.thb-portfolio .type-portfolio').not('.'+cat_value).hide();
      $('.thb-portfolio .type-portfolio').not('.'+aw_value).hide();
    } else if (cat_value != 'all' && aw_value == 'all'){
      $('.thb-portfolio .type-portfolio').show();
      $('.thb-portfolio .type-portfolio').not('.'+cat_value).hide();
    } else if (cat_value == 'all' && aw_value != 'all'){
      $('.thb-portfolio .type-portfolio').show();
      $('.thb-portfolio .type-portfolio').not('.'+aw_value).hide();
    } else {
      $('.thb-portfolio .type-portfolio').show();
    }

    //subcat
    if(cat_value && cat_value != 'all') {
      var parentid = $('.famabps_categoryfilter').children(":selected").attr("id").substring(1);

      $('.filtercontainer').hide();
      $('.filtercontainer'+parentid).show();

      var subcatval = $('.filtercontainer'+parentid+' select').val();
      if(subcatval && subcatval != 'all') {
        $('.thb-portfolio .type-portfolio').not('.'+subcatval).hide();
      }
    }


    // $('.masonry').isotope({
    //   itemSelector: '.columns',
    //   layoutMode: 'packery',
    //   percentPosition: true,
    //   itemSelector: '.columns',
    //   transitionDuration : 0,
    //   masonry: {
    //     columnWidth: 302
    //   }
    // });

    $('.portfolio-holder a').each(function(){
      var old = $(this).attr('href');
      if (old.indexOf("?") > 0) {
        var uri = old.substring(0, old.indexOf("?"));
      } else {
        var uri = old;
      }
      var current_page = encodeURIComponent(document.URL);
      var getstring = 'from='+current_page+'&categoryFilter='+cat_value+'&awardFilter='+aw_value;
      var href = uri+'?'+getstring;

      $(this).attr('href', href);
    });
  }

  var get_categoryFilter = GetURLParameter('categoryListFilter');
  var get_awardFilter = GetURLParameter('awardListFilter');

    if(get_categoryFilter && get_categoryFilter != "all" ) {
      $('#famabps_categoryfilter').val(get_categoryFilter).trigger('change.select2');
      var newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      famabps_filter();
    }
    if( get_awardFilter && get_awardFilter != "all" ) {
      $('#famabps_awardfilter').val(get_awardFilter).trigger('change.select2');
      var newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      famabps_filter();
    }



  $( ".famabps_filter" ).change(function() {
      famabps_filter();
  });

  $('.famabps_categoryfilter').change(function(){
    $('.famabps_subcategoryfilter').val('all');
  });

  var newURL = location.href.split("?")[0];
  window.history.pushState('object', document.title, newURL);

  famabps_filter();


});


var listrong = document.querySelectorAll( '.card-body li strong' )

if (listrong){
  Array.prototype.forEach.call(listrong, function(elem){
    console.log(elem.innerHTML)
    var text = elem.innerHTML.split( ' ' )
    if ( text[0] === 'GOLD' ){
      elem.parentNode.classList.add('gold')
    } else if ( text[0] === 'SIVLER' || text[0] === 'SILBER' ){
      elem.parentNode.classList.add('silver')
    } else if ( text[0] === 'BRONZE' ){
      elem.parentNode.classList.add('bronze')
    }
  })
}